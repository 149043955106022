<template>
  <div>
    <b-collapse
      class="collapse-areas-wrapper"
      animation="slide"
      v-model="openDropdown"
      aria-id="filter-area"
    >
      <template #trigger="props">
        <div
          class="specific-search-wrapper"
          aria-controls="filter-area"
          :aria-expanded="props.open"
        >
          <div>
            <p class="averta-black label-15">Cari Lebih Spesifik</p>
            <p class="font-grey-tertiary">Masukan hanya kata kunci yang kamu ingin temukan</p>
          </div>
          <ButtonApp :isSecondary="true">
            <div class="is-flex is-align-items-center py-1 bg-white">
              <p class="averta-bold mr-2">{{ openDropdown ? 'Sembunyikan' : 'Lihat Filter' }}</p>
              <div class="chevron-search-specific" :class="{ open: !openDropdown }">
                <img :src="require('@/assets/icons/chevron-down-red.svg')" alt="">
              </div>
            </div>
          </ButtonApp>
        </div>
      </template>
      <div class="mt-4 search-keyword-input">
        <p class="mb-1">Cari Berdasarkan</p>
        <b-input
          placeholder="ID/Nama PIC Proyek/No. Invoice"
          type="search"
          icon="magnify"
          icon-clickable
          v-model="keyword"
          @focus="resetOpenModal"
          @keyup.native.enter="handleSearch"
          class="averta-reguler label-14"
        >
        </b-input>

        <div class="body-specific-search">
          <p class="mb-1">Pilih Client</p>
          <b-field class="input-search-3 placeholder-specific-search">
            <b-autocomplete
              v-model="keywordVendor"
              placeholder="Pilih Client"
              :open-on-focus="true"
              :clearable="true"
              :data="filteredDataVendors"
              field="name"
              @select="(option) => handleSelectClient(option)"
            >
              <template slot-scope="props">
                <div class="item-client-wrapper">
                  <div class="logo-vendor-wrapper">
                    <img :src="props.option.logo" />
                  </div>
                  <p>{{ props.option.name }}</p>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
          <div class="form-specific-search">
            <div v-if="isSMRC" class="is-relative">
              <p class="mb-1">Pilih Area</p>
              <b-field class="input-search-3 placeholder-specific-search">
                <b-autocomplete
                  v-model="keywordArea"
                  placeholder="Pilih Area"
                  :open-on-focus="true"
                  :clearable="true"
                  :data="filteredDataAreas"
                  field="name"
                  @select="(option) => handleSelectItem(option, 'area')"
                >
                  <template slot-scope="props">
                    <div class="item-client-wrapper">
                      <p>{{ props.option.name }}</p>
                    </div>
                  </template>
                </b-autocomplete>
              </b-field>
            </div>
            <div class="is-relative">
              <p class="mb-1">Pilih {{ isSMRC ? 'Unit' : 'Cabang' }}</p>
              <b-field class="input-search-3 placeholder-specific-search">
                <b-autocomplete
                  v-model="keywordUnit"
                  :placeholder="`Semua ${isSMRC ? 'Unit' : 'Cabang'}`"
                  :open-on-focus="true"
                  :clearable="true"
                  :data="filteredDataUnits"
                  field="name"
                  @select="(option) => handleSelectItem(option, 'unit')"
                >
                  <template slot-scope="props">
                    <div class="item-client-wrapper">
                      <p>{{ props.option.name }}</p>
                    </div>
                  </template>
                </b-autocomplete>
              </b-field>
            </div>
            <div class="is-relative">
              <p class="mb-1">Pilih {{ isSMRC ? 'Building' : 'Outlet' }}</p>
              <b-field class="input-search-3 placeholder-specific-search">
                <b-autocomplete
                  v-model="keywordBuilding"
                  :placeholder="`Semua ${isSMRC ? 'Building' : 'Outlet'}`"
                  :open-on-focus="true"
                  :clearable="true"
                  :data="filteredDataBuilding"
                  field="name"
                  @select="(option) => handleSelectItem(option, 'building')"
                >
                  <template slot-scope="props">
                    <div class="item-client-wrapper">
                      <p>{{ props.option.name }}</p>
                    </div>
                  </template>
                </b-autocomplete>
              </b-field>
            </div>
            <div class="is-relative">
              <p class="mb-1">Filter Tanggal Mulai Proyek</p>
              <div class="input-filter-specific" @click="() => handleClickInput('startDate')">
                <div class="is-flex is-align-items-center">
                  <img :src="require('@/assets/icons/calendar-all-used.svg')" alt="" />
                  <p class="averta-bold ml-1">
                    {{ selectedStartDate ? formatDate(selectedStartDate) : 'Pilih Tanggal Mulai' }}
                  </p>
                </div>
                <a class="card-header-icon p-0">
                  <b-icon :icon="openStartDate ? 'menu-up' : 'menu-down'"> </b-icon>
                </a>
              </div>
              <div class="dropdown-specific-wrapper" v-if="openStartDate" v-click-outside="closeDropdownDate">
                <v-date-picker
                  v-model="selectedStartDate"
                  mode="date"
                  is-expanded
                  @dayclick="openStartDate = false"
                />
              </div>
            </div>
            <div class="is-relative">
              <p class="mb-1">Filter Tanggal Akhir Proyek</p>
              <div class="input-filter-specific" @click="() => handleClickInput('endDate')">
                <div class="is-flex is-align-items-center">
                  <img :src="require('@/assets/icons/calendar-all-used.svg')" alt="" />
                  <p class="averta-bold ml-1">
                    {{ selectedEndDate ? formatDate(selectedEndDate) : 'Pilih Tanggal Akhir' }}
                  </p>
                </div>
                <a class="card-header-icon p-0">
                  <b-icon :icon="openEndDate ? 'menu-up' : 'menu-down'"> </b-icon>
                </a>
              </div>
              <div class="dropdown-specific-wrapper" v-if="openEndDate" v-click-outside="closeDropdownDate">
                <v-date-picker
                  v-model="selectedEndDate"
                  mode="date"
                  is-expanded
                  @dayclick="openEndDate = false"
                />
              </div>
            </div>

            <DropdownStatus
              widthFilter="145%"
              :selectedStatusProps="selectedStatus"
              @handleSelectItem="handleSelectItem"
            />

          </div>
        </div>
        <div class="is-flex is-justify-content-end mt-4">
          <button
            class="btn-reset-specific mr-3"
            :class="{ disabled: disabled }"
            :disabled="disabled"
            @click="handleResetFilter"
          >
            <p class="averta-bold px-4 py-2">Reset Filter</p>
          </button>
          <ButtonApp :isDisabled="disabled" @click="handleSearch">
            <p class="averta-bold px-2 py-2">Tampilkan Hasil</p>
          </ButtonApp>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { alertErrorMessage, isSMRC } from '@/helpers'

export default {
  name: 'FormSpecificSearch',
  components: {
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    DropdownStatus: () => import('@/components/dropdown/DropdownStatus.vue')
  },
  data () {
    return {
      isLoadingVendor: false,
      isLoadingArea: false,
      isLoadingUnit: false,
      isLoadingBuilding: false,
      isLoadingStatus: false,
      vendors: [],

      keywordVendor: '',
      keywordArea: '',
      keywordUnit: '',
      keywordBuilding: '',

      openDropdown: false,
      openUnit: false,
      openBuilding: false,
      openStartDate: false,
      openEndDate: false,

      keyword: '',
      selectedClient: {
        name: null,
        code: null
      },
      selectedArea: {
        id: null,
        name: null
      },
      selectedUnit: {
        id: null,
        name: null
      },
      selectedBuilding: {
        id: null,
        name: null
      },
      selectedStartDate: '',
      selectedEndDate: '',
      selectedStatus: null,

      dataArea: [],
      dataUnit: [],
      dataBuilding: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      statusSpecific: 'projectV2/getStatusSpecific',
      getSpecificSearch: 'projectV2/getSpecificSearch'
    }),
    disabled () {
      return (
        (!this.keyword || this.keyword.trim() === '') &&
        (!this.selectedClient || !this.selectedClient.name) &&
        (!this.selectedArea || !this.selectedArea.id) &&
        (!this.selectedUnit || !this.selectedUnit.id) &&
        (!this.selectedBuilding || !this.selectedBuilding.id) &&
        !this.selectedStartDate &&
        !this.selectedEndDate &&
        (!this.selectedStatus || !this.selectedStatus.type)
      )
    },
    filteredDataVendors () {
      return this.vendors.filter((option) => {
        return option.name.toString().toLowerCase().indexOf(this.keywordVendor ? this.keywordVendor.toLowerCase() : '') >= 0
      })
    },
    filteredDataAreas () {
      return this.dataArea.filter((option) => {
        return option.name.toString().toLowerCase().indexOf(this.keywordArea ? this.keywordArea.toLowerCase() : '') >= 0
      })
    },
    filteredDataUnits () {
      return this.dataUnit.filter((option) => {
        return option.name.toString().toLowerCase().indexOf(this.keywordUnit ? this.keywordUnit.toLowerCase() : '') >= 0
      })
    },
    filteredDataBuilding () {
      return this.dataBuilding.filter((option) => {
        return option.name.toString().toLowerCase().indexOf(this.keywordBuilding ? this.keywordBuilding.toLowerCase() : '') >= 0
      })
    },
    isSMRC () {
      return isSMRC(this.selectedClient && this.selectedClient.code ? this.selectedClient.code : '')
    }
  },
  methods: {
    handleSelectClient (option) {
      this.selectedClient = option

      this.keywordArea = ''
      this.keywordUnit = ''
      this.keywordBuilding = ''

      this.dataArea = []
      this.dataUnit = []
      this.dataBuilding = []

      this.selectedArea = {
        id: null,
        name: null
      }
      this.selectedUnit = {
        id: null,
        name: null
      }
      this.selectedBuilding = {
        id: null,
        name: null
      }
      if (option && this.selectedClient && this.selectedClient.id) {
        if (this.isSMRC) {
          this.getListArea()
        } else {
          this.getListUnit()
        }
      }
    },
    handleSearch () {
      if (this.disabled) return
      const body = {
        keyword: this.keyword,
        client: this.selectedClient,
        area: this.selectedArea,
        unit: this.selectedUnit,
        building: this.selectedBuilding,
        start_date: this.selectedStartDate,
        end_date: this.selectedEndDate,
        type: this.selectedStatus ? this.selectedStatus.type : null,
        status: this.selectedStatus ? this.selectedStatus.status : null
      }
      this.$store.commit('projectV2/setSpecificSearch', body)
    },
    handleResetFilter () {
      this.keyword = ''
      this.keywordVendor = ''
      this.keywordArea = ''
      this.keywordUnit = ''
      this.keywordBuilding = ''

      this.selectedClient = {
        name: null,
        code: null
      }
      this.selectedArea = {
        id: null,
        name: null
      }
      this.selectedUnit = {
        id: null,
        name: null
      }
      this.selectedBuilding = {
        id: null,
        name: null
      }
      this.selectedStatus = null
      this.selectedStartDate = ''
      this.selectedEndDate = ''
    },
    handleClickInput (type) {
      if (type === 'area') {
        this.openUnit = false
        this.openBuilding = false
        this.openStartDate = false
        this.openEndDate = false
      }
      if (type === 'unit') {
        this.openUnit = !this.openUnit
        this.openBuilding = false
        this.openStartDate = false
        this.openEndDate = false
      }
      if (type === 'building') {
        this.openBuilding = !this.openBuilding
        this.openUnit = false
        this.openStartDate = false
        this.openEndDate = false
      }
      if (type === 'startDate') {
        this.openStartDate = !this.openStartDate
        this.openUnit = false
        this.openBuilding = false
        this.openEndDate = false
      }
      if (type === 'endDate') {
        this.openEndDate = !this.openEndDate
        this.openUnit = false
        this.openBuilding = false
        this.openStartDate = false
      }
      if (type === 'status') {
        this.openUnit = false
        this.openBuilding = false
        this.openStartDate = false
        this.openEndDate = false
      }
    },
    handleSelectItem (props) {
      const { data, type } = props
      if (type === 'area') {
        this.selectedArea = data

        this.keywordUnit = ''
        this.keywordBuilding = ''
        this.dataUnit = []
        this.dataBuilding = []

        this.selectedUnit = {
          id: null,
          name: null
        }
        this.selectedBuilding = {
          id: null,
          name: null
        }
        if (this.isSMRC && data) {
          this.getListUnit()
        }
      }
      if (type === 'unit') {
        this.selectedUnit = data
        this.selectedBuilding = {
          id: null,
          name: null
        }
        if (data) {
          this.getListBuilding()
        }
      }
      if (type === 'building') {
        this.selectedBuilding = data
      }
      if (type === 'startDate') {
        this.selectedStartDate = data
      }
      if (type === 'endDate') {
        this.selectedEndDate = data
      }
      if (type === 'status') {
        this.selectedStatus = data
      }
      this.resetOpenModal()
    },
    resetOpenModal () {
      this.openUnit = false
      this.openBuilding = false
      this.openStartDate = false
      this.openEndDate = false
    },
    closeDropdownDate () {
      this.openStartDate = false
      this.openEndDate = false
    },
    formatDate (date) {
      if (!date) return '-'
      return moment(date).format('dddd, DD MMMM YYYY')
    },
    getListVendor () {
      this.isLoadingVendor = true
      this.$store
        .dispatch('client/getListVendor', { url: this.url })
        .then((response) => {
          const res = response.data.data.list
          this.vendors = res
          this.isLoadingVendor = false
        })
        .catch((error) => {
          this.isLoadingVendor = false
          alertErrorMessage(error)
        })
    },
    getListArea () {
      this.isLoadingArea = true
      const payload = {
        url: this.url,
        vendor_id: this.selectedClient.id
      }

      this.$store
        .dispatch('client/getAreasSMRC', payload)
        .then(response => {
          const res = response.data.data
          this.dataArea = res.list
          this.isLoadingArea = false
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoadingArea = false
        })
    },
    getListUnit () {
      this.isLoadingUnit = false
      const payload = {
        url: this.url,
        vendor_id: this.selectedClient.id
      }

      if (this.isSMRC) {
        payload.area_id = this.selectedArea.id
      }

      this.$store
        .dispatch('client/getListBranch', payload)
        .then(response => {
          const res = response.data.data
          this.dataUnit = res.list
          this.isLoadingUnit = false
        })
        .catch(error => {
          this.isLoadingUnit = false
          alertErrorMessage(error)
        })
    },
    getListBuilding () {
      this.isLoadingBuilding = true

      const payload = {
        url: this.url,
        branch_id: this.selectedUnit ? this.selectedUnit.id : null,
        search: this.search
      }

      this.$store
        .dispatch('client/getListStore', payload)
        .then(response => {
          const res = response.data.data
          this.dataBuilding = res.list
          this.isLoadingBuilding = false
        })
        .catch(error => {
          this.isLoadingBuilding = false
          alertErrorMessage(error)
        })
    }

  },
  created () {
    this.getListVendor()
    setTimeout(() => {
      this.openDropdown = true
    }, 300)

    if (this.getSpecificSearch) {
      if (this.getSpecificSearch.keyword) {
        this.keyword = this.getSpecificSearch.keyword
      }
      if (this.getSpecificSearch.client && this.getSpecificSearch.client.name) {
        this.keywordVendor = this.getSpecificSearch.client.name
      }
    }
  },
  watch: {
    getSpecificSearch: {
      handler (val) {
        if (val.keyword) {
          this.keyword = val.keyword
        }
        if (val.client && val.client.name) {
          this.keywordVendor = val.client.name
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.collapse-areas-wrapper {
  border: 1px solid #e1e1e1;
  padding: 18px;
  border-radius: 10px;
  background: #fafafa;
}

.specific-search-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.body-specific-search {
  border-top: 1px dashed #c1c7d3;
  border-bottom: 1px dashed #c1c7d3;
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 20px;
}

.form-specific-search {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.input-filter-specific {
  border: 1px solid #e1e1e1;
  padding: 10px;
  border-radius: 10px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.dropdown-specific-wrapper {
  box-shadow: 6px 7px 50px 0px #00000014;
  position: absolute;
  top: 80px;
  right: 0px;
  width: 100%;
  background: white;
  z-index: 5;
  overflow: hidden;
}

.dropdown-specific-wrapper.status {
  width: 145%;
  border-radius: 10px;
}

.content-dropdown-wrapper {
  max-height: 160px;
  width: 100%;
  overflow: auto;
}

.content-dropdown-wrapper.status {
  max-height: 200px;
}

.item-content-dropdown {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.item-content-dropdown:hover, .item-content-dropdown.active {
  background: #e100091a;
}

.item-content-dropdown:not(:last-child) {
  border-bottom: 1px solid #e1e1e1;
}

.custom-radio-button {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #868686;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  flex-shrink: 0;
}

.custom-radio-button.active::before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 10px;
  background: #e10009;
}

.chevron-search-specific {
  position: relative;
  transition: all 0.2s ease-in-out;
}

.chevron-search-specific.open {
  transform: rotate(180deg);
  transform-origin: center;
}

.btn-reset-specific {
  border-radius: 100px;
  border: 1px solid #e10009;
  color: #e10009;
  background: white;
  cursor: pointer;
}

.btn-reset-specific.disabled {
  pointer-events: none;
  border: 1px solid #c1c7d3;
  color: #9a9a9a;
}

.item-client-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding: 5px 0;
}

.logo-vendor-wrapper {
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.logo-vendor-wrapper img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>

<style>
.placeholder-specific-search input, .placeholder-specific-search input::placeholder, .placeholder-specific-search input::-webkit-input-placeholder {
  color: #474747;
}

.search-keyword-input input {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  font-size: 14px;
  padding: 22px 10px;
}

.search-keyword-input span.icon {
  top: 6px !important;
}
</style>
